import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
  SocialShare,
  BreadCrumbs,
  PageLayout,
  DietitianSection,
  RenderVideoAndImage,
  FAQ,
  PageHeading,
  DietitianBookingsModal,
} from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const DieteticsServiceDetail: FC = ({ data, pageContext }: any) => {
  const dietetics = data.strapiDieteticsService;
  const dietitian = data.strapiDieteticsService.dietitians;
  const dieteticsImage =
    dietetics.image && dietetics.image.localFile
      ? getImage(dietetics.image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const { ServiceVideoImage, banner } = data.ServiceImageandVideo;
  const VideoGallery: any = [];
  useEffect(() => {
    VideoGallery.push(ServiceVideoImage.video?.localFile.publicURL);
  }, [VideoGallery]);
  const breadCrumb = [
    { title: 'Home', slug: '/' },
    {
      title: 'Nutrition',
      slug: '/services/nutrition',
    },
    { title: dietetics.title, slug: `/services/nutrition/${pageContext.slug}` },
  ];
  const [DietitianModalOpen, setDietitianModalOpen] = useState(false);
  const handleDietitianModalOpen = () => {
    setDietitianModalOpen(true);
  };
  return (
    <PageLayout
      title={dietetics.title}
      isMainBannerNav={false}
      metaTitle={dietetics.seo?.metaTitle}
      description={dietetics.seo?.metaDescription}
      image={dietetics.seo?.shareImage?.localFile?.publicURL}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Nutrition Page"
    >
      <div className="container">
        <div className="dietetics">
          <BreadCrumbs breadCrumb={breadCrumb} />
          <div className="dietetics_heading">
            <h1>{dietetics.title}</h1>
          </div>
          <div className="dietetics_image">
            <GatsbyImage image={dieteticsImage} alt={dietetics.title} />
            <div className="dietetics_shares">
              <SocialShare
                title={dietetics.title}
                image={dietetics.image?.localFile?.publicURL}
              />
            </div>
          </div>
          <div className="dietetics_wrapper">
            <p className="dietetics_summary">{dietetics.description}</p>
            <div className="dietetics_summary_description">
              <ReactMarkdown className="md-html">
                {dietetics.overview}
              </ReactMarkdown>
            </div>
          </div>
          <div className="dietetics_content">
            <ReactMarkdown className="md-html">
              {dietetics.content}
            </ReactMarkdown>
          </div>
          <div className="dietetics_content">
            <ReactMarkdown className="md-html">
              {dietetics.contentColumn1}
            </ReactMarkdown>
            <ReactMarkdown className="md-html">
              {dietetics.contentColumn2}
            </ReactMarkdown>
          </div>

          <div className="btn-link-wrapper">
            <button
              className="btn-diet-book"
              type="button"
              onClick={handleDietitianModalOpen}
            >
              <span>
                {data.callToAction?.title
                  ? data.callToAction.title
                  : data.DieticianLink.dietitianLink.bookDietitianButtonText}
              </span>
            </button>
          </div>
        </div>
      </div>
      <DietitianBookingsModal
        DietitianModalOpen={DietitianModalOpen}
        setDietitianModalOpen={setDietitianModalOpen}
        link="https://get-going.au3.cliniko.com/bookings?business_id=700133283232488984&embedded=true"
      />
      <DietitianSection
        dietitians={dietitian}
        meetdietitiancontent={dietetics.specialistsContent}
        dietitianLink={data.DieticianLink.dietitianLink}
        handleDietitianModalOpen={handleDietitianModalOpen}
      />
      {ServiceVideoImage && (
        <RenderVideoAndImage
          data={ServiceVideoImage}
          altText={dietetics.title}
          hasPlayButton
          hasBorder
          buttontype="text"
          buttonsize="large"
          videogallery={VideoGallery}
          showControls
          showModal
        />
      )}
      <div className="container">
        {dietetics.faq && (
          <section className="home-page-faq-section">
            <PageHeading
              title={dietetics.faq.title}
              subtitle={dietetics.faq.tagline}
            />
            <FAQ Faqs={dietetics.faq.faqs} />
          </section>
        )}
      </div>
    </PageLayout>
  );
};
export default DieteticsServiceDetail;

export const query = graphql`
  query DieteticsServiceQuery($slug: String) {
    strapiDieteticsService(slug: { eq: $slug }) {
      title
      description
      overview
      contentColumn1
      contentColumn2
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      callToAction {
        link
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      faq {
        tagline
        title
        faqs {
          answer
          title
        }
      }
      specialistsContent {
        content
        tagline
        title
      }
      dietitians {
        title
        slug
        qualification
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 520, height: 520)
            }
          }
        }
        description
      }
    }
    ServiceImageandVideo: strapiServicePage(slug: { eq: "nutrition" }) {
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ServiceVideoImage {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
    }
    DieticianLink: strapiGlobal {
      dietitianLink {
        bookDietitianButtonText
        bookDietitianLink
      }
    }
  }
`;
